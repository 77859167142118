import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "assets/css/quickContact.css";
import { Col, Row, Form, Container } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidEmail } from "constants/utils";
import { setEnquiryData } from "actions/userInfoAction";
import "assets/css/common.css";

export default function QuickContact() {
  const [showLoader, setShowLoader] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission!
      const { name, email, mobileNumber, company, comment, memberGrp } = form;
      let quickContactPayLoad = {
        name: name,
        email: email,
        mobileNo: mobileNumber?.numberWithCountryCode,
        companyName: company,
        typeOfEquiry: "",
        enquiryDetails: comment,
      };
      //Set Enquiry Data in DB
      setShowLoader(true);
      dispatch(setEnquiryData(quickContactPayLoad,
        () => { setShowLoader(false); },
        () => { setShowLoader(false); }));
    }
  };

  const findFormErrors = () => {
    const { name, email, mobileNumber, company, comment, memberGrp } = form;
    const newErrors = {};
    // name errors
    if (!name || name === "") newErrors.name = "Name cannot be blank!";
    else if (name.length > 100) newErrors.name = "Name is too long!";

    // add validation for email
    if (!email || email === "") { newErrors.email = "Email cannot be blank!"; }
    else if (!isValidEmail(email)) {
      newErrors.email = "Email is not Valid!";
    }
    // add validation for mobileNumber
    if (!mobileNumber?.number || mobileNumber?.number === "") { newErrors.mobileNumber = "Mobile Number cannot be blank!"; }
    // add validation for company
    if (!company || company === "")
      newErrors.company = "Company cannot be blank!";
    // add validation for comments
    if (!comment || comment === "")
      newErrors.comment = "Comments cannot be blank!";
    // if (!memberGrp || memberGrp === "") {
    //   newErrors.memberGrp = " please select any options";
    // }

    return newErrors;
  };
  const handleOnChange = (value, data, event, formattedValue) => {
    const mobileNumber = {
      countrCode: data.dialCode,
      numberWithCountryCode: value,
      number: value.slice(data.dialCode.length)
    }
    setField("mobileNumber", mobileNumber)
  }
  return (
    <>
      <div>
        {showLoader && (
          <div className="loader-wrap">
            <div className="loader quickContactLoader"></div>
          </div>
        )}
      </div>
      <Container>
        <Row id="quickContact" className="quickContactParentContainer">
          <Col md={1}></Col>
          <Col sm={12} md={4}>
            <div className="contactusTextContainer">
              <div className="contactUsText">Contact Us</div>
              <p className="questionText">
                Need assistance or have questions?
              </p>
              <p className="descriptionText">
                Request a callback by submitting your details, and we'll get back to you!
              </p>
            </div>
          </Col>
          <Col
            sm={12}
            md={6} className="quickContactFormCol py-4"
          >
            <Form className="quickContactFormContainer">
              <div className="quickContactFormDiv">
                <div className="quickContactFormText">Get in Touch</div>
                {/* <Form.Group>
            <Row className="GrpContainer">
               <Col>
                {" "}
                <Form.Check // prettier-ignore
                  type={"radio"}
                  name="memberGrp"
                  id="company"
                  label="Become a partner"
                  required
                  isInvalid={!!errors.memberGrp}
                  value="Become a partner"
                  onChange={(e) => setField("memberGrp", e.target.value)}
                />
              </Col>
              <Col>
                {" "}
                <Form.Check // prettier-ignore
                  type={"radio"}
                  name="memberGrp"
                  id="partner"
                  label="I am a partner"
                  required
                  isInvalid={!!errors.memberGrp}
                  value="I am a partner"
                  onChange={(e) => setField("memberGrp", e.target.value)}
                />
              </Col>
              <Col>
                {" "}
                <Form.Check // prettier-ignore
                  type={"radio"}
                  name="memberGrp"
                  id="need help"
                  label="need help"
                  isInvalid={!!errors.memberGrp}
                  value="need help"
                  onChange={(e) => setField("memberGrp", e.target.value)}
                />
              </Col>
              <Form.Control.Feedback type="invalid">
                {errors.memberGrp}
              </Form.Control.Feedback>
            </Row>
          </Form.Group> */}
                <Row className="GrpContainer">
                  <Form.Group>
                    <Form.Label className="required-field">Name</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setField("name", e.target.value)}
                      isInvalid={!!errors.name}
                      maxLength={100}
                      placeholder="Enter Name here"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="GrpContainer">
                  <Form.Group>
                    <Form.Label className="required-field">Email Id</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setField("email", e.target.value)}
                      isInvalid={!!errors.email}
                      placeholder="Enter Email Id here"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="GrpContainer">
                  <Form.Group>
                    <Form.Label className="required-field">Mobile Number</Form.Label>
                    <PhoneInput
                      inputClass={(!errors.mobileNumber) ? "mobileNumberInput" : "mobileNumberInput is-invalid"}
                      country={'ae'}
                      enableSearch={true}
                      countryCodeEditable={false}
                      autoFormat={false}
                      isValid={!errors.mobileNumber}
                      placeholder="Please select country"
                      //onChange={phone => setField("mobileNumber", phone)}
                      onChange={(value, data, event, formattedValue) => handleOnChange(value, data, event, formattedValue)}
                    // value={this.state.phone}
                    // onChange={phone => this.setState({ phone })}                                   
                    />
                    <Form.Text className="text-danger">
                      {errors.mobileNumber}
                    </Form.Text>
                  </Form.Group>
                </Row>
                <Row className="GrpContainer">
                  <Form.Group>
                    <Form.Label className="required-field">Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setField("company", e.target.value)}
                      isInvalid={!!errors.company}
                      maxLength={100}
                      placeholder="Enter Company Name here"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.company}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="GrpContainer">
                  <Form.Group>
                    <Form.Label className="required-field">Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={(e) => setField("comment", e.target.value)}
                      isInvalid={!!errors.comment}
                      maxLength={1000}
                      placeholder="Enter Comments here"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.comment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row
                  className="GrpContainer"
                  style={{ display: "flex", width: "30%" }}
                >
                  <div>
                    <button type="submit" className="btn btn-success quickContactButton" onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </Row>
              </div>
            </Form>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
    </>
  );
}
