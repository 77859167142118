import {
  ESTIMATE_SHIPPING_METHOD,
  GET_SHIPPING_INFORMATION,
  PLACE_ORDER,
} from "actions/types";

let initialState = {
  shippingMethods: [],
  shippingInformation: {},
  orderId: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ESTIMATE_SHIPPING_METHOD: {
      return {
        ...state,
        shippingMethods: action.payload,
      };
    }
    case GET_SHIPPING_INFORMATION: {
      return {
        ...state,
        shippingInformation: action.payload,
      };
    }
    case PLACE_ORDER: {
      return {
        ...state,
        orderId: action.payload,
      };
    }
    default:
      return state;
  }
};
