import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

const usePageTracking = () => {
  let location = useLocation();
  useEffect(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: location.pathname,
        },
      });     
  }, [location]);
};
export default usePageTracking;