import { SET_USER_INFO } from "actions/types";
import axios from "axios";
import API_LIST from "./apiList";
import SystemConstant from "constants/SystemConstant";
import { setAlert } from "./appActions";

export function getUserInfo(country_code) {
  return async (dispatch) => {
    const headers = SystemConstant.adminRequestHeaders;
    const postBody = {
      countryCode: country_code,
    };
    await axios
      .post(API_LIST.userInfo, postBody, { headers })
      .then((res) => {
        if (res.data) {
          dispatch(setUserInfoSuccess(res.data));
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText}`,
              SystemConstant.warning
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText}`,
            SystemConstant.warning
          )
        );
      });
  };
}

export function setEnquiryData(quickContactPayLoad, cbSuccess, cbError) {
  return (dispatch) => {
    const headers = SystemConstant.adminRequestHeaders;
    axios
      .post(`${API_LIST.setEnquiryData}`, quickContactPayLoad, { headers })
      .then((response) => {
        dispatch(
          setAlert(
            `Enquiry Saved Successfully. We will get in touch with you shortly!`,
            SystemConstant.success
          )
        );
        cbSuccess();
      })
      .catch((err) => {
        dispatch(
          setAlert(
            err?.response?.data?.message ||
              SystemConstant.somethingWentWrongText,
            SystemConstant.warning
          )
        );
        cbError();
      });
  };
}

export function setUserInfoSuccess(response) {
  return {
    type: SET_USER_INFO,
    payload: response,
  };
}
