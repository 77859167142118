import {
  LOGIN_USER,
  CLEAR_USER_DATA,
  USER_CART_SESSION,
  SET_CUSTOMER_DETAILS,
  SET_FIRSTNAME,
} from "actions/types";

let initialState = {
  userEmail: "",
  userCartToken: "",
  userDetails: {},
  firstname: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER: {
      return {
        ...state,
        userEmail: action.payload,
      };
    }
    case CLEAR_USER_DATA: {
      return {
        ...state,
        userEmail: "",
        userCartToken: "",
        userDetails: {},
        firstname: "",
      };
    }
    case USER_CART_SESSION: {
      return {
        ...state,
        userCartToken: action.payload,
      };
    }
    case SET_CUSTOMER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case SET_FIRSTNAME: {
      return {
        ...state,
        firstname: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
