import { SET_MAX_ORDER_QTY } from "actions/types";
import axios from "axios";
import API_LIST from "./apiList";
import SystemConstant from "constants/SystemConstant";
import { setAlert } from "./appActions";

export function getMaxOrderQty(cust_id, brand_id) {
  return (dispatch) => {
    const headers = SystemConstant.adminRequestHeaders;
    const access_code = SystemConstant.cookies.get("access_code");
    const postBody = {
      customer_id: cust_id,
      brand_id: brand_id,
      accessId: access_code
    };

    return axios
      .post(API_LIST.maxOrderQty, postBody, { headers })
      .then((res) => {
        if (res.data) {
          dispatch(getMaxOrderQtySuccess(res.data));
          return res.data;  // Return the response data
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText}`,
              SystemConstant.warning
            )
          );
          return null;  // Return null if no data
        }
      })
      .catch(() => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText}`,
            SystemConstant.warning
          )
        );
        return null;  // Return null in case of an error
      });
  };
}

export async function getMaxOrderQtyPerCategory(cust_id, brand_id) {
  const headers = SystemConstant.adminRequestHeaders;
  const access_code= SystemConstant.cookies.get("access_code");
  const postBody = {
    customer_id: cust_id,
    brand_id: brand_id,
    accessId: access_code
  };
  return new Promise((resolve, reject) => {
    axios
      .post(API_LIST.maxOrderQty, postBody, { headers })
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          console.error("problem with max order qty action");
          reject("problem with max order qty action");
        }
      })
      .catch(() => {
        console.error("problem with max order qty action");
        reject("problem with max order qty action");
      });
  });
}

export function getMaxOrderQtySuccess(response) {
  return {
    type: SET_MAX_ORDER_QTY,
    payload: response,
  };
}
