import React from 'react';
import { Nav, Placeholder } from 'react-bootstrap';

const HeaderSkeleton = () => {
    return (
        <>
            {[1, 2, 3, 4, 5, 6].map((_, index) => (
                <Nav.Link key={index} className="d-flex more-categories">
                    <div className="d-flex">
                        <Placeholder as="div" animation="wave" className="d-flex align-items-center">
                            <Placeholder xs={1} className="me-2" style={{ height: '15px', width: '100px' }} />
                            <Placeholder xs={8} className="w-100" />
                        </Placeholder>
                    </div>
                </Nav.Link>
            ))}
        </>
    );
};

export default HeaderSkeleton;
