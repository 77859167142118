import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "assets/css/profileAndCartInfo.css";
import USERICON from "assets/images/User.png";
import SCART_ICON from "assets/images/ShoppingCart.png";
import { NavDropdown, Button, Dropdown, Badge } from "react-bootstrap";
import SystemConstant from "constants/SystemConstant";
import { removeUserCookie } from "constants/utils";
import { getSession } from "actions/cartActions";
import { clearUserData } from "actions/signinActions";
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { IconContext } from "react-icons/lib";
import { BsFillPeopleFill } from "react-icons/bs";
import { useUpdateCartItems } from "../../hooks/useUpdateCartItems"

export default function ProfileAndCartInfo() {
  const { userEmail, firstname } = useSelector((state) => state.signinReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { totalItemsInCart, cartItems, viewCartItems } = useSelector((state) => state.cartReducer);
  useUpdateCartItems({ dispatch, totalItemsInCart, cartItems, viewCartItems });
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
  const logout = () => {
    removeUserCookie();
    // Connection to a broadcast channel
    const broadcastChannel = new BroadcastChannel("lenovoB2C_channel");
    // Example of sending of a very simple message
    broadcastChannel.postMessage("User is loggd Out.");
    // Close the channel when you're done.
    broadcastChannel.close();
    dispatch(getSession());
    dispatch(clearUserData());
    localStorage.removeItem("maxOrderQty")
    navigate("/");
    window.location.reload(false);
  };

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <div>
        <Dropdown className="btn-primary">
          <Dropdown.Toggle>
            <img
              src={USERICON}
              alt="user_profile"
              style={{ cursor: "pointer" }}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              {" "}
              {userEmail ? (
                <NavDropdown.Item className="logged-as">
                  <span className="d-inline-block text-truncate">
                    {SystemConstant.hiText}
                  </span>{" "}
                  <span className="fw-bold d-inline-block text-truncate logged-as-firstname">
                    {firstname}
                  </span>
                </NavDropdown.Item>
              ) : (
                ""
              )}
            </Dropdown.Item>
            <Dropdown.Item>
              {userEmail ? (
                <NavDropdown.Header>
                  <Button
                    className="btn btn-danger"
                    title={`Signed in as ${userEmail}`}
                    onClick={logout}
                  >
                    {SystemConstant.logoutText}
                  </Button>
                </NavDropdown.Header>
              ) : (
                ""
              )}
            </Dropdown.Item>
            <Dropdown.Item>
              {" "}
              {userEmail ? (
                <NavDropdown.Header>
                  <Nav.Link
                    as={Link}
                    to="/account"
                    className="text-decoration-none signup-text p-0 d-flex align-items-center gap-1"
                    eventKey="account"
                  >
                    <IconContext.Provider
                      value={{
                        color: primaryColor,
                        size: "1.3em",
                      }}
                    >
                      <BsFillPeopleFill></BsFillPeopleFill>
                    </IconContext.Provider>
                    {SystemConstant.myAccountText}
                  </Nav.Link>
                </NavDropdown.Header>
              ) : (
                <NavDropdown.Header
                  as={Link}
                  to="/signup"
                  className="signup-text"
                >
                  {SystemConstant.signUpInsteadText}
                </NavDropdown.Header>
              )}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{ display: "flex", alignItems: "center", width: "50%", "cursor": "pointer", "position": "relative" }} onClick={() => navigate('/viewcart')}>
        <img
          src={SCART_ICON}
          alt="shopping_cart"
          style={{ cursor: "pointer" }}
        />
        {totalItemsInCart > 0 && <Badge className="badgeItem" bg="light" text="success">{totalItemsInCart}</Badge>}
      </div>
    </div>
  );
}
