import { GET_COUNTRY_LIST_ADDRESS,GET_EMAIL_DOMAIN_ADDRESS,GET_REGION_LIST_ADDRESS } from "actions/types";

let initialState = {
  countryListForLoggedInUser: [],
  regionListForLoggedInUser: [],
  emailDomainForLoggedInUser:[]

};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_LIST_ADDRESS: {
      return {
        ...state,
        countryListForLoggedInUser: action.payload,
      };
    }
    case GET_REGION_LIST_ADDRESS: {
      return {
        ...state,
        regionListForLoggedInUser: action.payload,
      };
    }
    case GET_EMAIL_DOMAIN_ADDRESS:{
      return {
        ...state,
        emailDomainForLoggedInUser: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
