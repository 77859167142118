import {
  GET_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_SALE_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_COLOR_LIST,
  GET_SIZE_LIST,
  SET_PRODUCT_ATTR,
  SET_STOCK_QTY,
  SET_PRODUCT_ATTR_BY_ID,
  SET_STOCK_QTY_BY_ID,
  SET_PRODUCT_COUNT_TO_NULL,
  SET_RETURN_MANAGEMENT_DETAILS,
  GET_ORDER_HISTORY_ADDITIONAL_DETAILS,
  GET_RECOMMENDED_PRODUCTS,
} from "actions/types";
import { getColorSizeAndProducts } from "constants/utils";

let initialState = {
  productList: [],
  completeProductList: [],
  productById: {},
  productCount: null,
  dealsList: [],
  dealsListCount: null,
  completeDealsList: [],
  colorList: {},
  sizeList: {},
  productReturnDetails: [],
  orderHistoryAdditionalDetails: [],
  recommendedProductList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COLOR_LIST: {
      let colorObj = {};
      action.payload.forEach((color) => {
        if (color.label) {
          colorObj[color.value] = color.label;
        }
      });
      return {
        ...state,
        colorList: colorObj,
      };
    }
    case GET_SIZE_LIST: {
      let sizeObj = {};
      action.payload.forEach((size) => {
        if (size.label) {
          sizeObj[size.value] = size.label;
        }
      });
      return {
        ...state,
        sizeList: sizeObj,
      };
    }
    case GET_PRODUCTS: {
      let productsTemp = action.payload.filter(
        (product) => product.visibility === 4 && product?.price > 0
      );
      let newProducts = [];
      if (productsTemp.length) {
        newProducts = productsTemp
          .map((product) => {
            return getColorSizeAndProducts(
              product,
              state.colorList,
              state.sizeList,
              action.payload
            );
          })
          .sort((a, b) => {
            // If both `qty` values are 0, maintain the original order
            if (
              a.extension_attributes?.stock_item?.qty === 0 &&
              b.extension_attributes?.stock_item?.qty === 0
            ) {
              return 0;
            }

            // If one `qty` is 0, place it at the end
            if (a.extension_attributes?.stock_item?.qty === 0) {
              return 1;
            }
            if (b.extension_attributes?.stock_item?.qty === 0) {
              return -1;
            }

            // If both `qty` values are non-zero, sort by `qty` in descending order
            return (
              b.extension_attributes?.stock_item?.qty -
              a.extension_attributes?.stock_item?.qty
            );
          });
      }
      return {
        ...state,
        productList: newProducts,
        productCount: newProducts.length,
        completeProductList: action.payload,
      };
    }
    case GET_ALL_PRODUCTS: {
      let productsTemp = action.payload.filter(
        (product) => product.visibility === 4 && product.price > 0
      );
      let newProducts = [];
      if (productsTemp.length) {
        newProducts = productsTemp.map((product) => {
          return getColorSizeAndProducts(
            product,
            state.colorList,
            state.sizeList,
            action.payload
          );
        });
      }
      return {
        ...state,
        productList: newProducts,
        productCount: newProducts.length,
        completeProductList: action.payload,
      };
    }
    case GET_SALE_PRODUCTS: {
      let products = action.payload.filter(
        (product) =>
          product.visibility == 4 &&
          product?.extension_attributes?.stock_item?.qty > 0 &&
          product.price > 0 &&
          product?.extension_attributes?.on_sale_product === "1"
      );
      return {
        ...state,
        dealsList: products,
        dealsListCount: products.length,
        completeDealsList: action.payload,
      };
    }
    case SET_PRODUCT_ATTR: {
      const { attrName, atttrValue, sku } = action.payload;
      let index = state.productList.findIndex((pr) => pr.sku === sku);
      let tempProductList = [...state.productList];
      tempProductList[index][attrName] = atttrValue;
      return {
        ...state,
        productList: tempProductList,
      };
    }
    case SET_PRODUCT_ATTR_BY_ID: {
      const { attrName, atttrValue } = action.payload;
      let productByIdTemp = { ...state.productById };
      productByIdTemp[attrName] = atttrValue;
      return {
        ...state,
        productById: productByIdTemp,
      };
    }
    case SET_STOCK_QTY: {
      const { response, sku } = action.payload;
      let index = state.productList.findIndex((pr) => pr.sku === sku);
      let tempProductList = [...state.productList];
      tempProductList[index].inStock =
        response.extension_attributes.stock_item.qty > 0;
      return {
        ...state,
        productList: tempProductList,
      };
    }
    case SET_STOCK_QTY_BY_ID: {
      let productByIdTemp = { ...state.productById };
      productByIdTemp.inStock =
        action.payload.extension_attributes.stock_item.qty > 0;
      productByIdTemp.price = action.payload.price;
      return {
        ...state,
        productById: productByIdTemp,
      };
    }
    case GET_PRODUCT_BY_ID: {
      let productByIdTemp = getColorSizeAndProducts(
        action.payload,
        state.colorList,
        state.sizeList,
        state.completeProductList
      );
      return {
        ...state,
        productById: productByIdTemp,
      };
    }
    case SET_PRODUCT_COUNT_TO_NULL: {
      return {
        ...state,
        productCount: null,
      };
    }
    case SET_RETURN_MANAGEMENT_DETAILS: {
      return {
        ...state,
        productReturnDetails: action.payload,
      };
    }
    case GET_ORDER_HISTORY_ADDITIONAL_DETAILS: {
      return {
        ...state,
        orderHistoryAdditionalDetails: action.payload,
      };
    }
    case GET_RECOMMENDED_PRODUCTS: {
      return {
        ...state,
        recommendedProductList: action.payload,
      };
    }
    default:
      return state;
  }
};
