import {
  LOGIN_USER,
  CLEAR_USER_DATA,
  USER_CART_SESSION,
  SET_CUSTOMER_DETAILS,
  SET_FIRSTNAME,
} from "./types";
import SystemConstant from "constants/SystemConstant";
import API_LIST from "./apiList";
import axios from "axios";
import { setCookie, calculateMaxQty } from "constants/utils";
import { setAlert } from "./appActions";
import { getCartItems, getUserSession } from "./cartActions";
import { getUserInfo } from "./userInfoAction";
import { getMaxOrderQtyPerCategory } from "actions/maxOrderQtyAction";

export function loginUser(loginPayLoad, cbSuccess, cbFailure) {
  return (dispatch, getState) => {
    const headers = SystemConstant.adminRequestHeaders;
    const { totalItemsInCart } = getState().cartReducer;
    axios
      .post(`${API_LIST.userLogin}`, loginPayLoad, { headers })
      .then((response) => {
        const data = response.data;
        localStorage.removeItem("maxOrderQty")
        if (typeof data === 'string') {
          if (totalItemsInCart > 0) {
            setCookie(
              "oldGuestCartSession",
              SystemConstant.cookies.get("cartSession")
            );
          }
          setCookie("cartSession", data);
          setCookie("userEmail", loginPayLoad.username);
          //dispatch(setUserLoginEmail(loginPayLoad.username));
          cbSuccess();
        } else if(Array.isArray(data) && data[0]?.data?.status === 'failed') {
          dispatch(
            setAlert(data[0]?.data?.message || SystemConstant.failedToLoginText, SystemConstant.warning)
          );
          cbFailure();
        } else {
          dispatch(
            setAlert(SystemConstant.failedToLoginText, SystemConstant.warning)
          );
          cbFailure();
        }
      })
      .catch((err) => {
        dispatch(
          setAlert(
            err?.response?.data?.message ||
              SystemConstant.somethingWentWrongText,
            SystemConstant.warning
          )
        );
        cbFailure();
      });
  };
}

export function createUserCartSession(callback) {
  return (dispatch) => {
    const headers = SystemConstant.getUserRequestHeaders();
    axios
      .post(`${API_LIST.createUserCartSession}`, {}, { headers })
      .then((response) => {
        if (response.data) {
          setCookie("userCartToken", response.data);
          dispatch(setUserCartToken(response.data));
          dispatch(getCartItems());
          callback();
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.cartTokenFailedText}`,
              SystemConstant.warning
            )
          );
        }
      })
      .catch(() =>
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText} ${SystemConstant.cartTokenFailedText}`,
            SystemConstant.warning
          )
        )
      );
  };
}

export function getCustomerDetails(cb) {
  return async (dispatch) => {
    const headers = SystemConstant.getUserRequestHeaders();
    axios
      .get(`${API_LIST.getCustomerDetails}`, { headers })
      .then(async (response) => {
        if (response.data) {
          const access_code = response.data["custom_attributes"].find(
            (x) => x["attribute_code"] === "access_id"
          ).value;
          const company_code = response.data["custom_attributes"].find(
            (x) => x["attribute_code"] === "company_code"
          ).value;
          const cust_id = response.data["id"];
          const country_code = response.data["addresses"][0]["country_id"];
          setCookie("access_code", access_code);
          setCookie("company_code", company_code);
          setCookie("cust_id", cust_id);
          setCookie("country_code", country_code);
          if (cb) {
            cb(response.data);
          }
          dispatch(setUserDetails(response.data));
          dispatch(getUserInfo(country_code));
          // set the user cart info if any
          const userSession = getUserSession();

          const cartItems = await getCartItemsForLoggedInUserToCreateLookUp(
            userSession
          );
          if (cartItems && cartItems.length > 0) {
            cartItems.map((cartItem) => {
              setLookUpTablePerCategoryInCart(
                cust_id,
                cartItem["extension_attributes"]["product_category"],
                cartItem
              );
            });
          }
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.customerDetailsFailedText}`,
              SystemConstant.warning
            )
          );
        }
      })
      .catch(() =>
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText} ${SystemConstant.customerDetailsFailedText}`,
            SystemConstant.warning
          )
        )
      );
  };
}

export function mergeGuestAndUserCart(customerId, cb) {
  return (dispatch) => {
    const headers = SystemConstant.getUserRequestHeaders();
    const oldGuestCartSession = SystemConstant.cookies.get(
      "oldGuestCartSession"
    );
    const params = {
      customerId,
      storeId: 1,
    };
    axios
      .put(
        `${API_LIST.session}${oldGuestCartSession}`,
        { ...params },
        { headers }
      )
      .then((response) => {
        if (response.data) {
          SystemConstant.cookies.remove("oldGuestCartSession");
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.guestUserCartMergeFailedText}`,
              SystemConstant.warning
            )
          );
        }
        cb();
      })
      .catch(() => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText} ${SystemConstant.guestUserCartMergeFailedText}`,
            SystemConstant.warning
          )
        );
        cb();
      });
  };
}

async function getCartItemsForLoggedInUserToCreateLookUp(cartSession) {
  const userEmail = SystemConstant.cookies.get("userEmail");
  let getCartURL, headers;
  if (!userEmail) {
    // guest user
    let cartSessionTemp = cartSession;
    headers = SystemConstant.adminRequestHeaders;
    getCartURL = `${API_LIST.session}${cartSessionTemp}/${API_LIST.cartItems}`;
  } else {
    // logged in user
    headers = SystemConstant.getUserRequestHeaders();
    getCartURL = `${API_LIST.userCartItems}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(getCartURL, { headers })
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject("error in gettting cart items");
        }
      })
      .catch(() => reject("error in gettting cart items"));
  });
}

const setLookUpTablePerCategoryInCart = async (
  cust_id,
  categoryId,
  cartItem
) => {
  const maxOrderQtyPerCategory = await getMaxOrderQtyPerCategory(
    cust_id,
    categoryId
  );
  const createCartObj = {
    categoryId: categoryId,
    maxOderQty:
      +maxOrderQtyPerCategory["0"]["allowed_qty"] -
      +maxOrderQtyPerCategory["0"]["ordered_qty"],
    cust_id: cust_id,
    buyQty: +cartItem["qty"],
    sku: cartItem["sku"],
    action: "ADD",
  };
  if (+maxOrderQtyPerCategory[0]["allowed_qty"] > 0)
    calculateMaxQty(categoryId, createCartObj);
};

export function clearUserData() {
  return {
    type: CLEAR_USER_DATA,
  };
}

export function setUserLoginEmail(userEmail) {
  return {
    type: LOGIN_USER,
    payload: userEmail,
  };
}

export function setUserCartToken(cartToken) {
  return {
    type: USER_CART_SESSION,
    payload: cartToken,
  };
}

export function setUserDetails(userDetails) {
  return {
    type: SET_CUSTOMER_DETAILS,
    payload: userDetails,
  };
}

export function setFirstname(payload) {
  return {
    type: SET_FIRSTNAME,
    payload,
  };
}
