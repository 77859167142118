import {
    SET_USER_INFO
   } from "actions/types";

   let initialState = {
     userInfo:[]
   };

   export default (state = initialState, action) => {
     switch (action.type) {
       case SET_USER_INFO: {
         return {
           ...state,
           userInfo: action.payload,
         };
       }
       default: {
         return state;
       }
     }
   };
