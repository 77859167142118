import { useMemo } from 'react';
import { useSelector } from "react-redux";
//utility
import {
    checkAuth,
} from "constants/utils";
import SystemConstant from "../constants/SystemConstant";

export const useLoggedInUser = () => {
    const { userEmail } = useSelector((state) => state.signinReducer);
    const cartSession = SystemConstant.cookies.get("cartSession");
    const session_token = SystemConstant.cookies.get("userCartToken");

    const isLoggedInUser = useMemo(() => {
        return userEmail && checkAuth(cartSession) && session_token;
    }, [userEmail, cartSession, session_token]);

    return isLoggedInUser;
};