import {
  PAYMENT_VALIDATION_SUCCESS,
  PAYMENT_VALIDATION_ERROR,
} from "actions/types";

let initialState = {
  paymentState: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_VALIDATION_SUCCESS: {
      return {
        ...state,
        paymentState: action.payload,
      };
    }
    case PAYMENT_VALIDATION_ERROR: {
      return { ...state, paymentState: action.payload };
    }
    default: {
      return state;
    }
  }
};
