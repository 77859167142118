import {
  ADD_SHIPPING_METHODS,
  SET_SHIPPING_INFO,
  RESET_ORDER,
  TERMS_AND_CONDITIONS
} from "actions/types";

let initialState = {
  shippingMethods: [],
  shippingInfo: {},
  termsAndConditions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SHIPPING_METHODS: {
      return {
        ...state,
        shippingMethods: action.payload,
      };
    }
    case SET_SHIPPING_INFO: {
      return {
        ...state,
        shippingInfo: action.payload,
      };
    }
    case RESET_ORDER: {
      return {
        ...state,
        shippingInfo: {},
        shippingMethods: [],
      };
    }
    case TERMS_AND_CONDITIONS: {
      return {
        ...state,
        termsAndConditions: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
