import {
  GET_ALL_CATEGORY,
  SHOP_BY_BRAND,
  SHOP_BY_CATEGORY,
} from "actions/types";

let initialState = {
  allCategory: [],
  shopByCategory: [],
  shopByBrand: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY: {
      return {
        ...state,
        allCategory: action.payload,
      };
    }
    case SHOP_BY_BRAND: {
      return {
        ...state,
        shopByBrand: action.payload,
      };
    }
    case SHOP_BY_CATEGORY: {
      return {
        ...state,
        shopByCategory: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
