import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import appReducer from "./appReducer";
import productReducer from "./productReducer";
import checkoutReducer from "./checkoutReducer";
import signupReducer from "./signupReducer";
import signinReducer from "./signinReducer";
import userCheckoutReducer from "./userCheckoutReducer";
import accountReducer from "./accountReducer";
import paymentReducer from "./paymentReducer";
import privacyPolicyReducer from "./privacypolicyReducer";
import aboutUsReducer from "./aboutUsReducer";
import categoryReducer from './categoryReducer';
import maxOrderQtyReducer from './maxOrderQtyReducer';
import userInfoReducer from './userInfoReducer';
import addresReducer from './addresReducer';
import cookiePolicyReducer from "./cookiePolicyReducer";
import termsAndConditionPolicyReducer from "./termsAndConditionPolicyReducer";
import returnPolicyReducer from "./returnPolicyReducer";
import contactUsReducer from "./contactUsReducer";
import customerServiceReducer from "./customerServiceReducer";

export default combineReducers({
  appReducer,
  cartReducer,
  productReducer,
  checkoutReducer,
  signupReducer,
  signinReducer,
  userCheckoutReducer,
  accountReducer,
  paymentReducer,
  privacyPolicyReducer,
  aboutUsReducer,
  categoryReducer,
  maxOrderQtyReducer,
  userInfoReducer,
  addresReducer,
  cookiePolicyReducer,
  termsAndConditionPolicyReducer,
  returnPolicyReducer,
  contactUsReducer,
  customerServiceReducer,
});
