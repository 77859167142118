import { GET_COUNTRY_LIST, GET_REGION_LIST,GET_EMAIL_DOMAIN,GET_REGION_LIST_ERROR,GET_EMAIL_DOMAIN_ERROR } from "actions/types";

let initialState = {
  countryList: [],
  regionList: [],
  emailDomainError:false,
  regionListError:false

};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_LIST: {
      return {
        ...state,
        countryList: action.payload,
      };
    }
    case GET_REGION_LIST: {
      return {
        ...state,
        regionList: action.payload,
        regionListError:false
      };
    }
    case GET_EMAIL_DOMAIN:{
      return {
        ...state,
        emailDomain: action.payload,
        emailDomainError:false
      };
    }
    case GET_REGION_LIST_ERROR:{
      return {
        ...state,
        regionListError: true,
        regionList:[]
      };
    }
    case GET_EMAIL_DOMAIN_ERROR:{
      return {
        ...state,
        emailDomainError: true,
      };
    }
    default: {
      return state;
    }
  }
};
