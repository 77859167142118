import React  from "react";
import { useNavigate } from "react-router-dom";
import "assets/css/buttonPrimary.css";

export default function ButtonPrimary({btnntext,toLink}) {
  const navigate = useNavigate();
  const handlePageChange =()=>{
    navigate(toLink);
  }
  return (
    <div className="buttonContainer" onClick={handlePageChange}>
      <div className="buttonText"> {btnntext} </div>
    </div>
  );
}
