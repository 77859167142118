import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSession } from "actions/cartActions";
import { clearUserData } from "actions/signinActions";
import { searchProducts } from "actions/appActions";
import SystemConstant from "constants/SystemConstant";
import { useNavigate } from "react-router-dom";
import "assets/css/header.css";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { checkAuth, removeUserCookie } from "constants/utils";
import CustomSupport from "./customerSupport";
import CustomSearchAndProfile from "./customSearchAndProfile";
import {getAllCategories} from '../../actions/categoryAction'
import HeaderSkeleton from "./HeaderSkeleton";

export default function HeaderNew() {
  const [menuItemIndex, setMenuItemIndex] = useState(-2);
  const [searchText, setSearchText] = useState("");
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [innerHoverIndex, setInnerHoverIndex] = useState(-1);

  // const { categoryList } = useSelector((state) => state.appReducer);
  // const { totalItemsInCart } = useSelector((state) => state.cartReducer);
  const { userEmail } = useSelector((state) => state.signinReducer);
  const { allCategory } = useSelector((state) => state.categoryReducer)



  const cartSession = SystemConstant.cookies.get("cartSession");
  const session_token = SystemConstant.cookies.get("userCartToken");
  const access_code= SystemConstant.cookies.get("access_code");

  const location = useLocation();

  function checkActiveHeader() {
    if (location.pathname === "/") {
      setMenuItemIndex(-1);
    } else if (location.pathname.split("/")[1] === "category") {
      const index = parseInt(location.pathname.split("/")[2]) - 1;
      setMenuItemIndex(index);
    }
  }

  useEffect(() => {
    checkActiveHeader();
  }, []);

  useEffect(()=>{
    if(checkAuth(cartSession) && session_token && userEmail){
      dispatch(getAllCategories(access_code));
    }
  },[cartSession,session_token,userEmail])

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function logout() {
    removeUserCookie();
    // Connection to a broadcast channel
    const broadcastChannel = new BroadcastChannel("lenovoB2C_channel");
    // Example of sending of a very simple message
    broadcastChannel.postMessage("User is loggd Out.");
    // Close the channel when you're done.
    broadcastChannel.close();
    dispatch(getSession());
    dispatch(clearUserData());
    navigate("/");
    window.location.reload(false);
  }

  // const searchRef = useRef();

  // function searchTextChange(e) {
  //   setSearchText(e.target.value);
  // }

  // function handleSearchKepUp(keyRef) {
  //   if (keyRef.keyCode === 13) {
  //     if (searchText) {
  //       dispatch(searchProducts(searchText));
  //       navigate(`/search?text=${searchText}`);
  //     }
  //   }
  // }

  // function handleSearchClick() {
  //   if (searchText) {
  //     dispatch(searchProducts(searchText));
  //     navigate(`/search?text=${searchText}`);
  //   }
  // }

  const renderNavImage = (imgSrc) => {
    if (imgSrc) {
      // Check if imgSrc starts with http:// or https:// or /static
      if (imgSrc.toLowerCase().startsWith('http://') || imgSrc.toLowerCase().startsWith('https://') || imgSrc.startsWith('/static')) {
        return imgSrc;
      } else {
        let urlOfProductImage = process.env.REACT_APP_BASE_URL + imgSrc;
        return urlOfProductImage;
      }
    } else {
      return process.env.REACT_APP_BASE_URL + SystemConstant.fallbackImg;
    }
  };

  const onCategoryBtnClick = (e, item) => {
    e.preventDefault();
    if(item?.children_data?.length == 1) {
      const { children_data } = item;
      navigate(`/products?type=${children_data[0].entity_id}&parent=${children_data[0].parent_id}`)
    }
  }

  return (
    <>
      {/* <CustomSupport /> */}
      <CustomSearchAndProfile />

      {checkAuth(cartSession) && session_token && userEmail && (
        <Container>
          <div className="position-sticky pt-3 pe-3 ps-3 mynav-header">
            <div className="mynav">
              <Navbar bg="white" expand="lg" collapseOnSelect>
                <Container>
                  {/* <Navbar.Brand as={Link} to="/">
               {" "}
               <img src={HEADER_ICON} alt="" />
             </Navbar.Brand> */}
                  <Navbar.Toggle aria-controls="navbarScroll" />
                  <Navbar.Collapse id="navbarScroll">
                    <Nav
                    // className="me-auto my-2 my-lg-0"
                    // style={{ maxHeight: '100px' }}
                    // navbarScroll
                    >
                      {/* <Nav.Link as={Link} to="/" eventKey="home">
                   {SystemConstant.homeText}
                 </Nav.Link> */}
                      {checkAuth(cartSession) &&
                        session_token &&
                        userEmail &&
                        allCategory &&
                        allCategory.length > 0 ?
                        allCategory.map((item, index) => (
                          item.children_data && item.children_data.length > 0 && (
                          <Nav.Link
                            as={Link}
                            key={index}
                            //TODO: For testing purpose
                            // eventKey={item.id}
                            to={""}
                            className="d-flex more-categories align-items-center"
                            // To remove this onhover functionality, we just need to remove these onMouse events and their respective states. Also we need to remove the attribute show which is conditionally used.
                            onMouseEnter={() => setHoverIndex(index)}
                            onMouseLeave={() => setHoverIndex(-1)}
                            onClick={(e) => onCategoryBtnClick(e, item)}
                          >
                            <div className="d-flex align-items-center">
                              <div className="d-flex align-items-center">
                                <img
                                  src={renderNavImage(item.image)}
                                  alt=""
                                  width={35}
                                  height={30}
                                  style={{ marginRight: '5px', objectFit: 'contain'}}
                                />
                              </div>
                              <span className={`me-${item?.children_data?.length === 1 ? 2 : 0}`}>{item.name}</span>
                            </div>
                            {item.children_data &&
                              item.children_data.length > 1 && (
                                // commented for future use
                                // <NavDropdown className="drop-down-image">
                                <NavDropdown
                                  show={index === hoverIndex}
                                  className="drop-down-image"
                                >
                                  {item.children_data.map((item, index) => (
                                    <Nav.Link
                                      className="d-flex"
                                      key={index}
                                      //TODO: For testing purpose
                                      // eventKey={item.id}
                                      as={Link}
                                      to={`/products?type=${item.entity_id}&parent=${item.parent_id}`}
                                      onMouseEnter={() =>
                                        setInnerHoverIndex(index)
                                      }
                                      onMouseLeave={() =>
                                        setInnerHoverIndex(-1)
                                      }
                                    >
                                      {/* COmmented for time being */}
                                      {/* <Nav.Link
                                 eventKey={item.id}
                                 className="py-0 active"
                                 as={Link}
                                 to={`/products?type=${item.id}&parent=${item.parent_id}`}
                                 onMouseEnter={() => {console.log("Index is ", index);
                                 setInnerHoverIndex(index)}}
                                 // onMouseLeave={() => setInnerHoverIndex(-1)}
                               > */}
                                      <div eventKey={item.id}>{item.name}</div>
                                      {/* </Nav.Link> */}
                                      {item.children_data &&
                                        item.children_data.length > 0 && (
                                          <NavDropdown
                                            show={index === innerHoverIndex}
                                            className="drop-down-image"
                                          >
                                            {item.children_data.map(
                                              (item, dataIndex) => (
                                                <Nav.Link
                                                  as={Link}
                                                  to={`/products?type=${item.entity_id}&parent=${item.parent_id}`}
                                                  eventKey={item.entity_id}
                                                >
                                                  <div>{item.name}</div>
                                                </Nav.Link>
                                              )
                                            )}
                                          </NavDropdown>
                                        )}
                                    </Nav.Link>
                                  ))}
                                </NavDropdown>
                              )}
                          </Nav.Link>
                        ))): <HeaderSkeleton /> }
                    </Nav>

                    {/*  need to comment search & my cart */}
                    {/* <Nav className="ms-auto">
                 {userEmail ? (
                   <NavItem className="d-flex pe-3">
                     <>
                       <input
                         type="text"
                         placeholder="Search"
                         //className="me-2"
                         aria-label="Search"
                         onChange={searchTextChange}
                         onKeyUp={handleSearchKepUp}
                         value={searchText}
                         ref={searchRef}
                       />
                       <Button
                         onClick={handleSearchClick}
                         className="btn-sm"
                         variant="outline-success"
                       >
                         <BsSearch />
                       </Button>
                     </>
                   </NavItem>
                 ) : (
                   ""
                 )}
                 {checkAuth(cartSession) && session_token && userEmail ? (
                   // here is the carsession
                   <>
                     <NavItem>
                       <Container fluid>
                         <Row>
                           <Col xs={12}>
                             <NavDropdown
                               className="me-auto dropdown-menu-end"
                               title={<BsPerson />}
                               id="navbarScrollingDropdown"
                             >
                               {userEmail ? (
                                 <NavDropdown.Item className="logged-as">
                                   <span className="d-inline-block text-truncate">
                                     {SystemConstant.hiText}
                                   </span>{" "}
                                   <span className="fw-bold d-inline-block text-truncate logged-as-firstname">
                                     {firstname}
                                   </span>
                                 </NavDropdown.Item>
                               ) : (
                                 ""
                               )}
                               {userEmail ? (
                                 <NavDropdown.Header>
                                   <Button
                                     className="btn btn-danger"
                                     title={`Signed in as ${userEmail}`}
                                     onClick={logout}
                                   >
                                     {SystemConstant.logoutText}
                                   </Button>
                                 </NavDropdown.Header>
                               ) : (
                                 <NavDropdown.Header as={Link} to="/signin">
                                   <Button className="btn btn-primary">
                                     {SystemConstant.signInText}
                                   </Button>
                                 </NavDropdown.Header>
                               )}

                               <NavDropdown.Divider />
                               {userEmail ? (
                                 <NavDropdown.Header>
                                   <Nav.Link
                                     as={Link}
                                     to="/account"
                                     className="text-decoration-none signup-text p-0"
                                     eventKey="account"
                                   >
                                     <IconContext.Provider
                                       value={{
                                         color: "green",
                                         size: "1.3em",
                                       }}
                                     >
                                       <BsFillPeopleFill></BsFillPeopleFill>
                                     </IconContext.Provider>
                                     {SystemConstant.myAccountText}
                                   </Nav.Link>
                                 </NavDropdown.Header>
                               ) : (
                                 <NavDropdown.Header
                                   as={Link}
                                   to="/signup"
                                   className="signup-text"
                                 >
                                   {SystemConstant.signUpInsteadText}
                                 </NavDropdown.Header>
                               )}
                             </NavDropdown>
                           </Col>
                         </Row>
                       </Container>
                     </NavItem>
                     {
                       <Nav.Link
                         as={Link}
                         to="/viewcart"
                         className="cart-item"
                         eventKey="viewcart"
                       >
                         <BsCart4 />
                         {SystemConstant.myCartText}
                         {totalItemsInCart > 0 ? (
                           <span className="bg-warning badge text-dark fw-bold">
                             {totalItemsInCart}
                           </span>
                         ) : (
                           ""
                         )}
                       </Nav.Link>
                     }
                   </>
                 ) : (
                   <>
                     <Nav.Link
                       as={Link}
                       to="/signup"
                       className="fw-bold cart-item"
                       eventKey="signup"
                     >
                       <Button variant="outline-primary">
                         {SystemConstant.signUpText}
                       </Button>
                     </Nav.Link>
                     <Nav.Link
                       as={Link}
                       to="/signin"
                       className="fw-bold cart-item"
                       eventKey="signin"
                     >
                       <Button variant="success">
                         {SystemConstant.signInTitleCaseText}
                       </Button>
                     </Nav.Link>
                   </>
                 )}
               </Nav> */}
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
