import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";

let timeInterval = 5000;
export default function AlertComponent({ text, variant }) {
  const [show, setShow] = useState(true);

  let timer;
  useEffect(() => {
    let timer = window.setTimeout(() => {
      setShow(false);
    }, timeInterval);

    return () => {
      window.clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!show) {
      window.clearTimeout(timer);
    }
  }, [show]);

  return show ? (
    <Alert variant={variant}  dismissible style={{"zIndex":"1000"}}>
      <p className="fs-5 fw-bold">{text}</p>
    </Alert>
  ) : (
    ""
  );
}
