import { useEffect, useState, useRef } from "react";
import { deleteProductFromCart, getViewCartItems } from "actions/cartActions";
import { setAlert } from "actions/appActions";
import SystemConstant from "constants/SystemConstant";
import {
  deleteItemInLookUpHelper
} from "constants/utils";

export const useUpdateCartItems = ({ dispatch, totalItemsInCart, cartItems, viewCartItems }) => {
  const prevTotalItemsInCart = useRef(totalItemsInCart);
  const [isPrevTotalItemsInCartInitialized, setIsPrevTotalItemsInCartInitialized] = useState(false);
  const [deletedItems, setDeletedItems] = useState(new Set());
  const cust_id = SystemConstant.cookies.get("cust_id");

  useEffect(() => {
    if (totalItemsInCart !== null && !isPrevTotalItemsInCartInitialized) {
      prevTotalItemsInCart.current = totalItemsInCart;
      setIsPrevTotalItemsInCartInitialized(true);
    }
  }, [totalItemsInCart, isPrevTotalItemsInCartInitialized]);

  useEffect(() => {
    if (totalItemsInCart > 0 && totalItemsInCart === prevTotalItemsInCart.current) {
      dispatch(getViewCartItems(cartItems, () => { }))
    }
  }, [prevTotalItemsInCart.current]);

  useEffect(() => {
    if (viewCartItems?.length > 0 && totalItemsInCart === prevTotalItemsInCart.current) {
      const itemsToRemove = viewCartItems.filter(p => p?.price === 0 && p?.quote_id && p?.item_id && !deletedItems.has(p.item_id));
      let itemsRemoved = false;
      if (itemsToRemove.length > 0) {
        itemsToRemove.forEach((p, index) => {
          dispatch(deleteProductFromCart(p.quote_id, p.item_id, index));
          setDeletedItems((prev) => new Set(prev).add(p.item_id));
          deleteItemInLookUpHelper({item: p, cartItems, cust_id});
          itemsRemoved = true;
        });
      }
      if (itemsRemoved) {
        dispatch(setAlert(
          `${SystemConstant.removeInvalidItemFromCart}`,
          SystemConstant.warning
        ))
      }
    }
  }, [viewCartItems, dispatch]);
}