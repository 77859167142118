import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "assets/css/customSearchAndProfile.css";
import Logo_ICON from "assets/images/redington-white-logo.png";
import SEARCH_ICON from "assets/images/MagnifyingGlass.png";
import ButtonPrimary from "./buttonPrimary";
import ProfileAndCartInfo from "./profileAndCartInfo";
import SystemConstant from "constants/SystemConstant";
import { encodeParams } from "constants/utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { searchProducts } from "actions/appActions";
import { useLoggedInUser } from '../../hooks/useLoggedInUser';
import { useDeviceWidth } from '../../hooks/useDeviceWidth';

export default function CustomSearchAndProfile() {
  const cartSession = SystemConstant.cookies.get("cartSession");
  const session_token = SystemConstant.cookies.get("userCartToken");
  const { userEmail } = useSelector((state) => state.signinReducer);
  const [searchText, setSearchText] = useState("");
  const searchRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const deviceWidth = useDeviceWidth();

  const isLoggedInUser = useLoggedInUser();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTextParam = queryParams.get('text');
    if (searchTextParam) {
      setSearchText(searchTextParam);
    } else {
      setSearchText('');
    }
  }, [location.search]);

  const debouncedSearch = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(searchProducts(text));
        navigate(`/search?text=${encodeParams(text)}`);
      }
    }, 1000),
    []
  );

  function searchTextChange(e) {
    const text = e.target.value;
    setSearchText(text);
    debouncedSearch(text);
  }

  function handleSearchKepUp(keyRef) {
    if (keyRef.keyCode === 13) {
      if (searchText) {
        debouncedSearch.cancel();
        dispatch(searchProducts(searchText));
        navigate(`/search?text=${searchText}`);
      }
    }
  }

  // function handleSearchClick() {
  //   if (searchText) {
  //     dispatch(searchProducts(searchText));
  //     navigate(`/search?text=${searchText}`);
  //   }
  // }

  const handleUserProfileAndAuthView = () => {
    console.log("isLoggedInUser123", isLoggedInUser)
    if (isLoggedInUser) {
      return <ProfileAndCartInfo />;
    }
    return (
      <>
        <ButtonPrimary btnntext="Register" toLink="/signup" />
        <ButtonPrimary btnntext="Login" toLink="/signin" />
      </>
    );
  };
  return (
    <>
      <Container fluid className="outerBlock">
        <Container className="innnerBlock">
          <Row style={{ height: "100%" }}>
            <Col sm={4}>
              <div style={{ display: "flex", height: "100%","cursor":"pointer" }} onClick={()=> navigate(`/`)}>
                <img
                  className="imageLogo"
                  src={Logo_ICON}
                  alt="logo_icon"
                />
              </div>
            </Col>
            {/* <Col md-6 style={{ display: "flex", alignItems: "center" }}>
              <div className="searchContainer">Search</div>
            </Col> */}
            <Col
              sm={8}
              style={{
                display: "flex",
                alignItems: deviceWidth >= SystemConstant.DEVICE_SIZES.SMALL ? "center" : "flex-start",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                style={{ display: "flex", position: "relative", width: "100%", visibility: isLoggedInUser ? 'visible': 'hidden' }}
              >
                <input
                  className="searchContainer"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={searchTextChange}
                  onKeyUp={handleSearchKepUp}
                  value={searchText}
                  ref={searchRef}
                ></input>
                <img
                  className="searchIcon"
                  src={SEARCH_ICON}
                  alt="search_icon"
                ></img>
              </div>

              {/* <ButtonPrimary btnntext="Register" toLink="/signup"/>
            <ButtonPrimary btnntext="Login" toLink="/signin"/> */}
              {/* <ProfileAndCartInfo /> */}
              {handleUserProfileAndAuthView()}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
