import React, { useState } from "react";
import HEADER_ICON from "assets/images/redington-logo-color.png";
import "assets/css/footer.css";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import SystemConstant from "constants/SystemConstant";
import apiList from "actions/apiList";
import REDINGTON_BRAND from "assets/images/RedingtonFooter.png";
import QuickContact from "components/quickcontact/quickContact";

export default function Footer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  return (
    <>
      <Modal size='xl' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuickContact />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="mt-auto mb-5 pb-4">
        {/* <div className="bg-white px-3">
        <Row>
          <Col>
            <hr className="bg-dark" />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row>
              <Col>
                <Link to="/">
                  <img src={HEADER_ICON} alt="" />
                </Link>
              </Col>
              <Col>
                <h5>{SystemConstant.knowUsText}</h5>
                {SystemConstant.footerContent.knowUsArr &&
                  SystemConstant.footerContent.knowUsArr.length > 0 &&
                  SystemConstant.footerContent.knowUsArr.map((obj) => (
                    <Row key={obj.text}>
                      <Col>
                        <Link className="text-dark" to={`${obj.link}`}>
                          {obj.text}
                        </Link>
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </Col>
          <Col md={6} className="mt-3 mt-md-0">
            <Row>
              <Col>
                <h5>{SystemConstant.policiesText}</h5>
                {SystemConstant.footerContent.policiesArr &&
                  SystemConstant.footerContent.policiesArr.length > 0 &&
                  SystemConstant.footerContent.policiesArr.map((obj) => (
                    <Row key={obj.text}>
                      <Col>
                        {obj?.type === "link" ? (
                          <Link className="text-dark" to={`${obj.link}`}>
                            {obj.text}
                          </Link>
                        ) : (
                          <a
                            className="text-dark"
                            href={apiList.returnPolicyUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {obj.text}
                          </a>
                        )}
                      </Col>
                    </Row>
                  ))}
              </Col>
              <Col>
                <h5>{SystemConstant.contactUsText}</h5>
                {SystemConstant.footerContent.contactArr &&
                  SystemConstant.footerContent.contactArr.length > 0 &&
                  SystemConstant.footerContent.contactArr.map((obj) => (
                    <Row key={obj.text}>
                      <Col>
                        <Link className="text-dark" to={`${obj.link}`}>
                          {obj.text}
                        </Link>
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="bg-dark text-white-50 text-center px-0 mx-3">
            &copy; {SystemConstant.footerCompanyText}
          </Col>
        </Row>
      </div> */}
        <Row>
          <Col sm={6}>
            <div className="footerBrandContainer mt-5 pt-2">
              <img
                src={REDINGTON_BRAND}
                alt="redington_brand"
                style={{ width: "40%", paddingBottom: "10px" }}
              />
              <p className="footerBrandText">Redington Gulf FZE </p>
              <p className="footerBrandText">
                Level 16 Burjuman Business Tower
              </p>
              <p className="footerBrandText">
                Sheikh Khalifa Bin Zayed St, Al Mankhool, Dubai, United Arab
                Emirates
              </p>
              <p className="footerBrandText footerContactWrapper" style={{ color: "#019047" }}>
                <div className="footerEmailText">{`Email: supportb2b@redingtongroup.com `}</div>
                <div>{`Phone: +971 4 516 1000`}</div>
              </p>
            </div>
          </Col>
          <Col sm={3}>
            <div className="footerBrandContainer mt-5">
              <p className="footerBrandText" style={{ fontWeight: "700" }}>
                Company
              </p>
              <a className="anchorText" href="/aboutus">
                <p className="footerBrandText" style={{ paddingLeft: "10px" }}>
                  About Us
                </p>
              </a>
              <a className="anchorText" href="/">
                <p className="footerBrandText" style={{ paddingLeft: "10px" }}>
                  Distribution
                </p>
              </a>
              <a className="anchorText" href="/faqs">
                <p className="footerBrandText" style={{ paddingLeft: "10px" }}>
                  FAQs
                </p>
              </a>
              <a className="anchorText" href="/contactus">
                <p className="footerBrandText" style={{ paddingLeft: "10px" }}>
                  Contact Us
                </p>
              </a>
              <Button variant="success" className="footer-getintouch-btn" onClick={handleShow}>Get in touch</Button>
            </div>
          </Col>
          <Col sm={3}>
            <div className="footerBrandContainer mt-5">
              <p className="footerBrandText" style={{ fontWeight: "700" }}>
                Policy
              </p>

              <a className="anchorText" href="/privacypolicy">
                <p className="footerBrandText" style={{ paddingLeft: "10px" }}>
                  Privacy Policy
                </p>
              </a>
              <a className="anchorText" href="/cookiepolicy">
                <p className="footerBrandText" style={{ paddingLeft: "10px" }}>
                  Cookie Policy
                </p>
              </a>
              <a className="anchorText" href="/returnpolicy">
                <p className="footerBrandText" style={{ paddingLeft: "10px" }}>
                  Return Policy
                </p>
              </a>
              <a className="anchorText" href="/termsandconditions">
                <p className="footerBrandText" style={{ paddingLeft: "10px" }}>
                  Terms & Condition
                </p>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="footerBrandNameContainer">
        Redington Gulf FZE© 2024
      </Container>
    </>
  );
}
