import {
  GET_ALL_CATEGORY,
  SHOP_BY_BRAND,
  SHOP_BY_CATEGORY,
} from "actions/types";
import axios from "axios";
import API_LIST from "./apiList";
import SystemConstant from "constants/SystemConstant";
import { setAlert } from "./appActions";

export function getAllCategories(access_code) {
  return async (dispatch) => {
    const headers = SystemConstant.adminRequestHeaders;
    const postBody = {
      categoryType: "all",
      accessId: access_code,
    };
    await axios
      .post(API_LIST.getCategory, postBody, { headers })
      .then((res) => {
        if (res.data) {
          dispatch(getAllCategoriesSuccess(res.data));
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText}`,
              SystemConstant.warning
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText}`,
            SystemConstant.warning
          )
        );
      });
  };
}
export function getShopByCategory() {
  return async (dispatch) => {
    const headers = SystemConstant.adminRequestHeaders;
    const postBody = {
      categoryType: "category",
    };
    await axios
      .post(API_LIST.getCategory, postBody, { headers })
      .then((res) => {
        if (res.data) {
          dispatch(getShopByCategorySuccess(res.data));
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText}`,
              SystemConstant.warning
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText}`,
            SystemConstant.warning
          )
        );
      });
  };
}

export function getShopByBrandAction(access_code) {
  return async (dispatch) => {
    const headers = SystemConstant.adminRequestHeaders;
    const postBody = {
      categoryType: "brand",
      accessId: access_code,
    };

    await axios
      .post(API_LIST.getCategory, postBody, { headers })
      .then((res) => {
        if (res.data) {
          dispatch(getShopByBrandSuccess(res.data));
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText}`,
              SystemConstant.warning
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText}`,
            SystemConstant.warning
          )
        );
      });
  };
}

export function getAllCategoriesSuccess(response) {
  return {
    type: GET_ALL_CATEGORY,
    payload: response,
  };
}

export function getShopByBrandSuccess(response) {
  return {
    type: SHOP_BY_BRAND,
    payload: response,
  };
}

export function getShopByCategorySuccess(response) {
  return {
    type: SHOP_BY_CATEGORY,
    payload: response,
  };
}
