import {
  GET_SESSION,
  GET_CART_ITEMS,
  DELETE_PRODUCT_FROM_CART,
  GET_VIEW_CART_ITEMS,
  UPDATE_VIEW_CART_ITEMS,
  CLEAR_VIEW_CART,
  UPDATE_CART_ERROR,
} from "actions/types";

let initialState = {
  cartSession: "",
  cartItems: "",
  totalItemsInCart: null,
  totalPriceOfItemsInCart: 0,
  viewCartItems: [],
  updateCartError: "",
};

function getTotalItems(itemList) {
  return itemList.reduce(
    (acc, currentValue) => acc + Number(currentValue.qty),
    0
  );
}

function getTotalPriceOfItems(itemList) {
  // if("custom_attributes" in itemList){
  //   return 0;
  // }
  // else{
          if(itemList?.length > 0 && "custom_attributes" in itemList[0]){
          return itemList.reduce(
            (acc, currentValue) =>
            {
              let price = 0;
              let indexOfSpecialPrice = currentValue.custom_attributes.findIndex(
                (attr) => attr.attribute_code === 'special_price');
              price = (indexOfSpecialPrice !== -1)? Number(currentValue.custom_attributes[indexOfSpecialPrice].value) : Number(currentValue.price);
              return acc + Number(currentValue.qty) *  price},
            0
          );
        }
        else{
  return itemList.reduce(
    (acc, currentValue) =>
    {
      return acc + Number(currentValue.qty) * Number(currentValue.price)},
    0
  );
}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SESSION: {
      return {
        ...state,
        cartSession: action.payload,
      };
    }
    case GET_CART_ITEMS: {
      return {
        ...state,
        cartItems: action.payload.length ? action.payload : [],
        totalItemsInCart: action.payload.length
          ? getTotalItems(action.payload)
          : 0,
        totalPriceOfItemsInCart: action.payload.length
          ? getTotalPriceOfItems(action.payload)
          : 0,
      };
    }
    case GET_VIEW_CART_ITEMS: {
      let viewCartItemsTemp = [...action.payload];
      for (let i = 0; i < viewCartItemsTemp.length; i++) {
        if (state.cartItems[i]?.qty) {
          viewCartItemsTemp[i].qty = state.cartItems[i].qty;
        }
        if (state.cartItems[i]?.quote_id) {
          viewCartItemsTemp[i].quote_id = state.cartItems[i].quote_id;
        }
        if (state.cartItems[i]?.item_id) {
          viewCartItemsTemp[i].item_id = state.cartItems[i].item_id;
        }
      }
      return {
        ...state,
        viewCartItems: viewCartItemsTemp,
        totalItemsInCart: getTotalItems(action.payload),
        totalPriceOfItemsInCart: getTotalPriceOfItems(action.payload),
      };
    }
    case UPDATE_VIEW_CART_ITEMS: {
      let { sku, qty } = action.payload;
      let viewCartItemsTemp = [...state.viewCartItems];
      const updatedItemIndex = viewCartItemsTemp.findIndex(
        (item) => item.sku === sku
      );
      viewCartItemsTemp[updatedItemIndex].qty = qty;
      return {
        ...state,
        viewCartItems: viewCartItemsTemp,
        totalItemsInCart: getTotalItems(viewCartItemsTemp),
        totalPriceOfItemsInCart: getTotalPriceOfItems(viewCartItemsTemp),
      };
    }
    case DELETE_PRODUCT_FROM_CART: {
      let newItemsInArray = [...state.viewCartItems];
      newItemsInArray.splice(action.payload, 1);
      return {
        ...state,
        viewCartItems: newItemsInArray,
        totalItemsInCart: getTotalItems(newItemsInArray),
        totalPriceOfItemsInCart: getTotalPriceOfItems(newItemsInArray),
      };
    }
    case CLEAR_VIEW_CART: {
      return {
        ...state,
        viewCartItems: [],
        totalItemsInCart: null,
        totalPriceOfItemsInCart: 0,
      };
    }
    case UPDATE_CART_ERROR: {
      return {
        ...state,
        updateCartError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
