import { Toast } from 'react-bootstrap';
import React, { useState } from 'react';
import SystemConstant from "constants/SystemConstant";
import { IoInformationCircleOutline, IoCheckmarkCircleOutline, IoWarningOutline, IoAlertCircleOutline } from "react-icons/io5";
//CSS
import "assets/css/commonComponents/toast.css";

const toastStyle = {
    defaultBackgroundColor: '#f0f0f0',
    variantColors: {
        [SystemConstant.info]: '#cfe2ff',
        [SystemConstant.success]: '#d1e7dd',
        [SystemConstant.warning]: '#f39c12',
        [SystemConstant.error]: '#e74c3c',
    }
}

const variantIcon = {
    [SystemConstant.info]: IoInformationCircleOutline,
    [SystemConstant.success]: IoCheckmarkCircleOutline,
    [SystemConstant.warning]: IoWarningOutline,
    [SystemConstant.error]: IoAlertCircleOutline,
};

export default function Toaster({ text, key, variant }) {
    const [show, setShow] = useState(true);
    const backgroundColor = toastStyle.variantColors[variant] || toastStyle.defaultBackgroundColor;
    const IconComponent = variantIcon[variant] || IoInformationCircleOutline;

    return (
        <Toast className="toast-container" style={{ backgroundColor }} animation show={show} onClose={() => setShow(false)} delay={3000} autohide key={key}>
             <Toast.Header className='mb-0'>
                <IconComponent size={24} /> 
                <strong className="me-auto text-uppercase px-1">{variant}</strong>
            </Toast.Header>
            <Toast.Body className='toast-text'>{text}</Toast.Body>
        </Toast>
    );
};

