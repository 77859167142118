import { SET_ABOUT_US_CONTENT } from "actions/types";

let initialState = {
  aboutUsContent: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ABOUT_US_CONTENT: {
      return {
        ...state,
        aboutUsContent: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
