import { SET_PRIVACY_POLICY_CONTENT } from "actions/types";

let initialState = {
  privacyPolicyContent: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRIVACY_POLICY_CONTENT: {
      return {
        ...state,
        privacyPolicyContent: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
