export const API_CALL_FAILED = "API_CALL_FAILED";
export const SET_ALERT = "SET_ALERT";
export const SET_TOAST = "SET_TOAST";
export const GET_COLOR_LIST = "GET_COLOR_LIST";
export const GET_SIZE_LIST = "GET_SIZE_LIST";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_SALE_PRODUCTS = "GET_SALE_PRODUCTS";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_SESSION = "GET_SESSION";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const DELETE_PRODUCT_FROM_CART = "DELETE_PRODUCT_FROM_CART";
export const GET_VIEW_CART_ITEMS = "GET_VIEW_CART_ITEMS";
export const UPDATE_VIEW_CART_ITEMS = "UPDATE_VIEW_CART_ITEMS";
export const ESTIMATE_SHIPPING_METHOD = "ESTIMATE_SHIPPING_METHOD";
export const GET_SHIPPING_INFORMATION = "GET_SHIPPING_INFORMATION";
export const PLACE_ORDER = "PLACE_ORDER";
export const SET_PRODUCT_ATTR = "SET_PRODUCT_ATTR";
export const SET_STOCK_QTY = "SET_STOCK_QTY";
export const SET_PRODUCT_ATTR_BY_ID = "SET_PRODUCT_ATTR_BY_ID";
export const SET_STOCK_QTY_BY_ID = "SET_STOCK_QTY_BY_ID";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_REGION_LIST = "GET_REGION_LIST";
export const LOGIN_USER = "LOGIN_USER";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const USER_CART_SESSION = "USER_CART_SESSION";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const ADD_SHIPPING_METHODS = "ADD_SHIPPING_METHODS";
export const SET_SHIPPING_INFO = "SET_SHIPPING_INFO";
export const RESET_ORDER = "RESET_ORDER";
export const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS";
export const SET_ACCOUNT_ORDERS = "SET_ACCOUNT_ORDERS";
export const SET_ORDER_ITEM_DETAILS = "SET_ORDER_ITEM_DETAILS";
export const SET_SEARCH_PRODUCTS = "SET_SEARCH_PRODUCTS";
export const SET_SEARCH_LOADER = "SET_SEARCH_LOADER";
export const SET_SEARCH_PRODUCT_ATTR = "SET_SEARCH_PRODUCT_ATTR";
export const SET_PRODUCT_COUNT_TO_NULL = "SET_PRODUCT_COUNT_TO_NULL";
export const CLEAR_VIEW_CART = "CLEAR_VIEW_CART";
export const SET_FIRSTNAME = "SET_FIRSTNAME";
export const UPDATE_CART_ERROR = "UPDATE_CART_ERROR";
export const SET_RETURN_MANAGEMENT_DETAILS = "SET_RETURN_MANAGEMENT_DETAILS";
export const GET_ORDER_HISTORY_ADDITIONAL_DETAILS =
  "GET_ORDER_HISTORY_ADDITIONAL_DETAILS";
export const GET_RECOMMENDED_PRODUCTS = "GET_RECOMMENDED_PRODUCTS";
export const PAYMENT_VALIDATION_ERROR = "PAYMENT_VALIDATION_ERROR";
export const PAYMENT_VALIDATION_SUCCESS = "PAYMENT_VALIDATION_SUCCESS";
export const SET_PRIVACY_POLICY_CONTENT = "SET_PRIVACY_POLICY_CONTENT";
export const SET_ABOUT_US_CONTENT = "SET_ABOUT_US_CONTENT";
export const SET_CONTACT_US_CONTENT = "SET_CONTACT_US_CONTENT";
export const SET_CUSTOMER_SERVICE_CONTENT = "SET_CUSTOMER_SERVICE_CONTENT";
export const GET_EMAIL_DOMAIN = "GET_EMAIL_DOMAIN"
export const GET_REGION_LIST_ERROR= "GET_REGION_LIST_ERROR"
export const GET_EMAIL_DOMAIN_ERROR= "GET_EMAIL_DOMAIN_ERROR"


export const ERROR_MSG = "ERROR_OCCURED"
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const SHOP_BY_CATEGORY = "SHOP_BY_CATEGORY";
export const SHOP_BY_BRAND = "SHOP_BY_BRAND";
export const SET_CUSTOM_ATTR = "SET_CUSTOM_ATTR";
export const SET_MAX_ORDER_QTY = "SET_MAX_ORDER_QTY";
export const SET_USER_INFO= "SET_USER_INFO";

export const GET_COUNTRY_LIST_ADDRESS = "GET_COUNTRY_LIST_ADDRESS";
export const GET_REGION_LIST_ADDRESS = "GET_REGION_LIST_ADDRESS";
export const GET_EMAIL_DOMAIN_ADDRESS = "GET_EMAIL_DOMAIN_ADDRESS"
export const SET_COOKIE_POLICY_CONTENT = "SET_COOKIE_POLICY_CONTENT";
export const SET_TERMSANDCONDITION_POLICY_CONTENT = "SET_TERMSANDCONDITION_POLICY_CONTENT";
export const SET_RETURN_POLICY_CONTENT = "SET_RETURN_POLICY_CONTENT";
