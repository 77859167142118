import {
   SET_MAX_ORDER_QTY
  } from "actions/types";

  let initialState = {
    maxOrderQty:[]
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_MAX_ORDER_QTY: {
        return {
          ...state,
          maxOrderQty: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
