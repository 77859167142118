import { SET_ACCOUNT_ORDERS, SET_ORDER_ITEM_DETAILS } from "actions/types";

let initialState = {
  accountOrders: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_ORDERS: {
      return {
        ...state,
        accountOrders: action.payload,
      };
    }
    case SET_ORDER_ITEM_DETAILS: {
      const { itemDetails, orderIndex } = action.payload;
      const accountOrdersTemp = { ...state.accountOrders };
      accountOrdersTemp.items[orderIndex].itemInfoExists = true;
      accountOrdersTemp.items[orderIndex].items.forEach((item, index) => {
        item.info = itemDetails[index];
      });
      return {
        ...state,
        accountOrders: accountOrdersTemp,
      };
    }
    default: {
      return state;
    }
  }
};
